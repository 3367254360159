import { Link } from "react-router-dom";
import { signTopics } from "./global_sign";

const ListSign = () => {
    const list = signTopics;
    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Biển báo</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div className="controls-container">
                <div className="item-container">
                    {list.map((q, index) => (
                        <div style={{ borderBottom: '#e9e7e712', borderBottomWidth: '1px', borderBottomStyle: 'solid' }} key={index}>
                            <Link to={`/sign?index=0&signType=${q.signType}`}>
                                <div style={{ margin: '10px', marginLeft: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img style={{ width: '40px', height: '40px', marginRight: '10px' }} src={`images/assets/topic_sign_${index}.png`} alt={`Biển báo loại ${index + 1}`} />
                                        <b > {q.display}</b>
                                    </div>
                                    <div style={{ color: '#ffffffb0' }}>{q.subTitle}</div>
                                </div>
                            </Link>
                        </div>
                    ))}
                    <div>
                        <a href="https://apps.apple.com/app/id6451360985">
                            <div style={{ margin: '10px', marginLeft: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img style={{ borderRadius: '6px', width: '40px', height: '40px', marginRight: '10px' }} src="images/assets/pdf_scan_logo.png" alt="iScanX" />
                                    <b> iScanX </b>
                                </div>
                                <div style={{ color: '#ffffffb0' }}>Hỗ trợ chúng tôi bằng cách tải về ứng dụng iScanX của nhà tài trợ của chúng tôi</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListSign;
