import { Link } from "react-router-dom";
import { getParaCurr } from "./global_js";
import {signTopics, fullSigns} from "./global_sign";
const Sign = () => {
    const signType = getParaCurr("signType");
    const signs = fullSigns.filter(function(sign){return sign.signType == signType})
    const signTopic = signTopics.filter(function(topic){return topic.signType == signType})[0]
  return (
    <div>
      <div className="head-navigation">
        <Link className="left" to="/list-sign">
          <span  className="fa fa-chevron-left"></span>
        </Link>
        <div className="center">
          <strong>{signTopic.display}</strong>
        </div>
        <div className="right"></div>
      </div>
      <p></p>
      <div id="list-danger-page" className="page has-menu">
        <div id="list" style={{ padding: '20px' }}>
          {signs.map((q, index) => (
            <div className="item" key={index} style={{ borderBottom: '#e9e7e7', borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
              <div>
                <b >{q.code}: {q.name}</b>
              </div>
              <div id={q.code} style={{ marginTop: '10px', display: 'flex' }}>
                <img src={`images/signs/sign${q.image}.png`} style={{ width: '100px', height: '100px' }} alt={`Biển báo ${q.name}`} />
                <div className="a-text" style={{ marginLeft: '7px' }}>{q.detail}</div>
              </div>
              <div style={{ marginTop: '10px' }}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sign;

