import './App.css';
import fullExams from './global_exam';
import { fullLicenses } from './global_question';
import { Link } from 'react-router-dom';

const ListExam = () => {
    const getSavedExam = (licenseCode, examCode) => {
        const key = "is_saveexam_" + licenseCode + "_" + examCode
        return localStorage.getItem(key)
    }

    const licenseCode = localStorage.getItem("is_license") || ""

    const license = fullLicenses.filter((item) => { return item.code == licenseCode })[0]

    const licensePass = license.pass

    const exams = fullExams.filter(function (exam) { return exam.licenseCode.includes(license.code) })

    const topics = Array.from(new Set(exams.map((item) => item.exam)))

    const list = []
    for (let i = 0; i < topics.length; i++) {
        const saved = getSavedExam(licenseCode, topics[i]);
        var result = JSON.parse(saved);

        list.push({
            index: topics[i],
            css_class: result ? (result.result == 0 ? "background-color:#dc1354;" : "background-color:#0171ec") : "",
            title_css: result ? "color:white;" : "color:#0171ec;",
            passed: result ? result.passed : 0,
            failed: result ? result.failed : 0,
            danger: result ? result.danger : 0,
            unchecked: result ? result.unchecked : 0,
            duration: result ? result.time : "",
            result: result ? (result.result == 1 ? "ĐẠT" : "CHƯA ĐẠT") : "",
            saved: result,
        });
    }

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Bộ đề thi {licenseCode}</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div id="list-exam-page" className="page has-menu">
                <h5 style={{ textAlign: 'center', marginTop: '0px', fontWeight: 'normal' }}>Phải trả lời đúng {licensePass} câu và không sai câu liệt</h5>
                <div id="list">
                    {list.map((item, index) => (
                        <Link id="exam" to={`/exam?examCode=${item.index}`} key={index} style={{
                            borderRadius: '22px',
                            borderWidth: '0px',
                            padding: '10px',
                            color: 'white',
                            height: '130px',
                            width: '165px',
                            backgroundColor: item.saved ? (item.saved.result == 0 ? '#dc1354' : '#0171ec') : '#ffffff60'
                        }}>
                            <span style={{ color: 'white', fontWeight: 'bold' }} >Đề số {item.index}</span>

                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '2px' }}>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '2px' }}>
                                    <img style={{ height: '20px', width: '20px' }} src="images/assets/check.png" />
                                    <span style={{ paddingLeft: '10px', fontSize: '16px' }}>{item.passed}</span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '2px' }}>
                                    <img style={{ height: '20px', width: '20px' }} src="images/assets/wrongcheck.png" />
                                    <span style={{ paddingLeft: '10px', fontSize: '16px' }}>{item.failed}</span>
                                </div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '2px' }}>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '2px' }}>
                                    <img style={{ height: '20px', width: '20px' }} src="images/assets/uncheck.png" />
                                    <span style={{ paddingLeft: '10px', fontSize: '16px' }}>{item.unchecked}</span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '2px' }}>
                                    <img style={{ height: '20px', width: '20px' }} src="images/assets/clock.png" />
                                    <span style={{ paddingLeft: '10px', fontSize: '16px' }}>{item.duration}</span>
                                </div>
                            </div>
                            <div style={{ width: '100%', alignItems: 'center', padding: '2px' }}>{item.result}</div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ListExam;
