import React from 'react';
import { Link } from 'react-router-dom';
import { getParaCurr } from './global_js';
import { fullLawTopics, fullViolations } from "./global_violation";

const ListViolation = () => {
    const violationGroup = getParaCurr("violationGroup");
    const violationType = getParaCurr("violationType");

    const topic = fullLawTopics.filter(function (law) {
        return law.code == violationGroup
    })[0];

    const list = fullViolations.filter(function (violation) {
        return violation.topicCode == violationGroup && violation.code == violationType
    });

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/list-law">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>{topic.display}</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div id="list-danger-page" className="page has-menu">
                <div id="list">
                    {list.map((q, index) => (
                        <div className="item" key={index} style={{ borderBottom: '1px dashed #a0a0a0', marginTop: '20px' }}>
                            <Link to={`/violation?index=${q.no}&violationGroup=${topic.code}&violationType=${violationType}`}>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <b >{q.violation}</b>
                                    </div>
                                    <div style={{ color: 'red' }}>{q.fines}</div>
                                    <div >Xem chi tiết</div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ListViolation;
