import { freshQuestions } from './global_question';
import { Link } from "react-router-dom";

const ListTopic = () => {
  const paletteColor = [
    "#aa66cc",
    "#ff8800",
    "#0099cc",
    "#669900",
    "#0096ff",
    "#aa66cc",
    "#ff4444",
    "#008f51",
    "red",
    "#03DAC5",
    "#ffbb33",
    "#aa66cc",
    "#ff8800",
    "#0099cc",
    "#669900",
    "#ff8800",
    "#aa66cc",
    "#ff4444",
    "#cc0000",
    "#f17a0a",
    "#03DAC5",
    "#ffbb33",
  ];

  const resetTopic = (fullQuestions) => {

    const numOfQuestion = fullQuestions.length
    const numOfTopic1 = fullQuestions.filter(function (question) { return question.topic == 1 }).length
    const numOfTopic2 = fullQuestions.filter(function (question) { return question.topic == 2 }).length
    const numOfTopic3 = fullQuestions.filter(function (question) { return question.topic == 3 }).length
    const numOfTopic4 = fullQuestions.filter(function (question) { return question.topic == 4 }).length
    const numOfTopic5 = fullQuestions.filter(function (question) { return question.topic == 5 }).length
    const numOfTopic6 = fullQuestions.filter(function (question) { return question.topic == 6 }).length
    const numOfTopic7 = fullQuestions.filter(function (question) { return question.topic == 7 }).length
    const numOfTopic8 = fullQuestions.filter(function (question) { return question.required > 0 }).length

    const topics = [
      { "code": 0, "display": "Toàn bộ câu hỏi", "subTitle": `${numOfQuestion} câu`, "num": numOfQuestion },
      { "code": 1, "display": "Khái niệm và quy tắc giao thông", "subTitle": `${numOfTopic1} câu`, "num": numOfTopic1 },
      { "code": 2, "display": "Nghiệp vụ vận tải", "subTitle": `${numOfTopic2} câu`, "num": numOfTopic2 },
      { "code": 3, "display": "Văn hoá và đạo đức", "subTitle": `${numOfTopic3} câu`, "num": numOfTopic3 },
      { "code": 4, "display": "Kỹ thuật lái xe", "subTitle": `${numOfTopic4} câu`, "num": numOfTopic4 },
      { "code": 5, "display": "Cấu tạo và sữa chữa", "subTitle": `${numOfTopic5} câu`, "num": numOfTopic5 },
      { "code": 6, "display": "Biển báo và đường bộ", "subTitle": `${numOfTopic6} câu`, "num": numOfTopic6 },
      { "code": 7, "display": "Sa hình", "subTitle": `${numOfTopic7} câu`, "num": numOfTopic7 },
      { "code": 8, "display": "Câu liệt", "subTitle": `${numOfTopic8} câu`, "num": numOfTopic8 }];

    return topics;
  };

  const licenseCode = localStorage.getItem("is_license") || ""
  const { fullQuestions } = freshQuestions(licenseCode);
  const list = resetTopic(fullQuestions)

  return (
    <>
      <div className="head-navigation">
        <Link className="left" to="/">
          <span  className="fa fa-chevron-left"></span>
        </Link>
        <div className="center">
          <strong>Chủ đề</strong>
        </div>
        <div className="right">
        </div>
      </div>
      <p></p>
      <div className="controls-container">
        <div className="item-container">
          {list.map((q, index) => (
            <div key={index} style={{ borderBottom: '#e9e7e712', borderBottomWidth: q.num > 0 ? '1px' : '0px', borderBottomStyle: 'solid' }}>
              <div className="item" style={{ display: q.num > 0 ? 'block' : 'none', margin: '10px', marginLeft: '20px' }}>
                <Link to={`/question?index=0&topic=${index}`}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ borderRadius: '6px', backgroundColor: paletteColor[index], width: '38px', height: '38px', marginRight: '10px' }} src={`images/assets/topic_code_${q.code}.png`} />
                      <div>
                        <b >{q.display}</b>
                        <div style={{ color: '#ffffffb0' }}>{q.subTitle}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
          <div>
            <div className="item" style={{ display: 'block', margin: '10px', marginLeft: '20px' }}>
              <a href="https://apps.apple.com/app/id6451360985">
                <div>
                  <div style={{ display: 'flex' }}>
                    <img style={{ borderRadius: '6px', width: '38px', height: '38px', marginRight: '10px' }} src="images/assets/pdf_scan_logo.png" />
                    <div>
                      <b>iScanX</b>
                      <div style={{ color: '#ffffffb0' }}>Hỗ trợ chúng tôi bằng cách tải về ứng dụng iScanX của nhà tài trợ của chúng tôi</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListTopic;