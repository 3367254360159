import { Link } from "react-router-dom";
import { getParaCurr } from "./global_js";
import { fullLawTopics, fullViolations } from "./global_violation";

const Violation = () => {

    const violationNo = getParaCurr("index")
    const violationGroup = getParaCurr("violationGroup")

    const topic = fullLawTopics.filter(function (law) {
        return law.code == violationGroup
    })[0]

    const violation = fullViolations.filter(function (violation) {
        return violation.no == violationNo
    })[0]

    const list = fullViolations.filter(function (violation) {
        return violation.relations.includes(violation.no)
    })

    const bookmarks1 = violation.bookmarks.filter(function (book) {
        return book.bookmarkType == 1
    })

    const bookmarks2 = violation.bookmarks.filter(function (book) {
        return book.bookmarkType == 2
    })

    const bookmarks3 = violation.bookmarks.filter(function (book) {
        return book.bookmarkType == 3
    })
    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to={`/list-violation?violationGroup=${topic.code}&violationType=${topic.code}`}>
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>{topic.display}</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div id="list-danger-page" className="page has-menu">
                <div id="list">
                    <div style={{ marginBottom: '10px' }}>
                        <h4>Hành vi</h4>
                        <div style={{ display: 'flex' }}>
                            <span >{violation.entities}</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b>{violation.violation}</b>
                        </div>
                    </div>

                    <div style={{ marginBottom: '10px', borderTop: '1px dashed #a0a0a0' }}>
                        <h4>Hình phạt</h4>
                        <div style={{ color: 'red' }}>{violation.fines}</div>
                        {bookmarks1.map((b1, index) => (
                            <div key={index}>
                                <a href={`/nd100.html#${b1.bookmarkCode}`}>
                                    <h5 >Xem chi tiết {b1.bookmark}</h5>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginBottom: '10px', borderTop: '1px dashed #a0a0a0', display: violation.additionalPenalties ? 'block' : 'none' }}>
                        <h4>Hình phạt bổ sung</h4>
                        <div style={{ display: 'flex' }}>
                            <span>{violation.additionalPenalties}</span>
                        </div>
                        {bookmarks2.map((b2, index) => (
                            <div key={index}>
                                <a href={`/nd100.html#${b2.bookmarkCode}`}>
                                    <h5 >Xem chi tiết {b2.bookmark}</h5>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginBottom: '10px', borderTop: '1px dashed #a0a0a0', display: violation.otherPenalties ? 'block' : 'none' }}>
                        <h4>Ghi chú</h4>
                        <div style={{ display: 'flex' }}>
                            <span>{violation.otherPenalties}</span>
                        </div>
                    </div>

                    <div style={{ marginBottom: '10px', borderTop: '1px dashed #a0a0a0', display: violation.remedial ? 'block' : 'none' }}>
                        <h4>Khắc phục hậu quả</h4>
                        <div style={{ display: 'flex' }}>
                            <span>{violation.remedial}</span>
                        </div>
                        {bookmarks3.map((b3, index) => (
                            <div key={index}>
                                <a href={`/nd100.html#${b3.bookmarkCode}`}>
                                    <h5 >Xem chi tiết {b3.bookmark}</h5>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div style={{ marginBottom: '10px', borderTop: '1px dashed #a0a0a0', display: list.length ? 'block' : 'none' }}>
                        <h4>Hành vi liên quan</h4>
                        {list.map((q, index) => (
                            <div key={index} style={{ borderBottom: '1px dashed #a0a0a0', marginTop: '0px' }} className="item">
                                <Link to={`/violation?index=${q.no}&violationGroup=${topic.code}`}>
                                    <div>
                                        <div style={{ color: '#0171ec', display: 'flex' }}>
                                            {q.violation}
                                        </div>
                                        <div style={{ color: 'red' }}>{q.fines}</div>
                                        <h5 >Xem chi tiết</h5>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Violation;
