const getPara = (url, name) => {
    url = url + ""; // convert to string
    let array = url.split(/\?|&/);
    let i = 0;
    for (i = 1; i < array.length; i++) {
        if (array[i].split('=')[0].toLowerCase() == name.toLowerCase()) return array[i].split('=')[1];
    }
    return '';
}

export const getParaCurr = (name) => {
    let url = document.location;
    return getPara(url, name);
}

export const isAnswered = (licenseCode, questionIndex, answerIndex) => {
    return localStorage.getItem("is_answer_" + licenseCode + "_" + questionIndex) == answerIndex
}

export const isAnsweredWrong = (question, licenseCode, questionIndex) => {
    for (let i = 0; i < question.answers.length; i++) {
        let answer = question.answers[i];
        if (answer.correct && !isAnswered(licenseCode, questionIndex, i)) return true;
        if (!answer.correct && isAnswered(licenseCode, questionIndex, i)) return true;
    }
    return false;
}

export const hasAnswered = (question, licenseCode, questionIndex) => {
    for (let i = 0; i < question.answers.length; i++) {
        if (isAnswered(licenseCode, questionIndex, i)) {
            return true;
        }
    }
    return false;
}

export default { getParaCurr, isAnsweredWrong, hasAnswered, isAnswered }