import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fullLawTopics } from "./global_violation";

const ListLaw = () => {
    const [violationType, setViolationType] = useState('1');
    const list = fullLawTopics;
    const handleViolationTypeChange = (event) => {
        setViolationType(event.target.value);
    };

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Luật giao thông</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div id="list-danger-page" className="page has-menu">
                <div className="item" style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }}>
                    <input type="radio" id="bycicle" title="Xe máy" name="lawRadio" onChange={handleViolationTypeChange} value="1" checked={violationType == '1'} />
                    <label htmlFor="bycicle"> Xe máy</label>
                    <span> | </span>
                    <input type="radio" id="car" title="Xe ô tô" name="lawRadio" onChange={handleViolationTypeChange} value="2" checked={violationType == '2'} />
                    <label htmlFor="car"> Xe ô tô</label>
                    <span> | </span>
                    <input type="radio" id="other" title="Khác" name="lawRadio" onChange={handleViolationTypeChange} value="3" checked={violationType == '3'} />
                    <label htmlFor="other"> Khác</label>
                </div>
                <div className="controls-container">
                    <div className="item-container">
                        {list.map((q, index) => (
                            <div style={{ borderBottom: '#e9e7e712', borderBottomWidth: '1px', borderBottomStyle: 'solid' }} key={index}>
                                <Link to={`/list-violation?violationGroup=${q.code}&violationType=${violationType}`}>
                                <div style={{ margin: '10px', marginLeft: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: '40px', height: '40px', marginRight: '10px' }} src={`images/assets/topic_${q.code}.png`} alt={`${q.display}`} />
                                            <b > {q.display}</b>
                                        </div>
                                        <div style={{ color: '#ffffffb0' }}>{q.subTitle}</div>
                                    </div>
                                </Link>
                            </div>

                        ))}
                        <div>
                        <a href="https://apps.apple.com/app/id6451360985">
                            <div style={{ margin: '10px', marginLeft: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img style={{ borderRadius: '6px', width: '40px', height: '40px', marginRight: '10px' }} src="images/assets/pdf_scan_logo.png" alt="iScanX" />
                                    <b > iScanX </b>
                                </div>
                                <div style={{ color: '#ffffffb0' }}>Hỗ trợ chúng tôi bằng cách tải về ứng dụng iScanX của nhà tài trợ của chúng tôi</div>
                            </div>
                        </a>
                    </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
}

export default ListLaw;