import { freshQuestions } from './global_question';
import { Link } from 'react-router-dom';
import { isAnsweredWrong, hasAnswered, isAnswered } from "./global_js";

const ListWrong = () => {

    const licenseCode = localStorage.getItem("is_license") || ""
    const { fullQuestions } = freshQuestions(licenseCode);

    const hasChecked = (questionIndex, answerIndex) => {
        return isAnswered(licenseCode, questionIndex, answerIndex);
    }

    const getResultClass = (questionIndex, answerIndex, answer) => {
        if (answer.correct) {
            return "correct";
        } else if (isAnswered(licenseCode, questionIndex, answerIndex)) {
            return "wrong";
        } else {
            return "";
        }
    }

    let list = []
    fullQuestions.forEach(function (question) {
        if (hasAnswered(question, licenseCode, question.index) && isAnsweredWrong(question, licenseCode, question.index)) {
            list.push(question);
        }
    })

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Các câu sai</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div id="list-wrong-page" className="page has-menu">
                <div style={{ padding: '10px', display: list.length ? 'none' : 'block' }}>
                    Xin chúc mừng, hiện tại bạn không có câu nào làm sai!
                </div>
                <div id="list">
                    {list.map((q, index) => (
                        <div className='question-card item' style={{ maxWidth: '600px' }} key={index}>
                            <div>
                                <b>Câu {q.no}: {q.text}</b>
                            </div>
                            <div id="q-image" style={{ display: q.image ? 'block' : 'none', marginTop: '10px', textAlign: 'center' }}>
                                <img src={`images/questions/${q.image}`} alt={`Question ${q.no}`} />
                            </div>
                            <div id="answers" style={{ marginTop: '10px' }}>
                                {q.answers.map((a, answerIndex) => (
                                    <div className={`answer ${getResultClass(q.index, answerIndex, a)}`} key={answerIndex}>
                                        <div style={{ width: '30px' }}>
                                            <input style={{ width: '20px', height: '20px' }} type="radio" id={`ans${answerIndex}`} disabled="disabled" checked={hasChecked(q.index, answerIndex)} />
                                        </div>
                                        <div className="a-text" style={{ marginLeft: '7px' }}>{a.text}</div>
                                    </div>
                                ))}
                            </div>
                            <div id="q-tip" style={{ display: q.tip ? 'block' : 'none', marginTop: '10px' }}>
                                <i>Hướng dẫn: {q.tip}</i>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ListWrong;
