import { freshQuestions } from './global_question';
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getParaCurr, isAnswered } from "./global_js";

const Question = () => {
    const initData = useMemo(
        () => {
            const loadQuestionByTopic = (topicCode) => {
                if (topicCode == "" || topicCode == "0") {
                    return fullQuestions;
                } else if (topicCode == "8") {
                    return fullQuestions.filter(function (question) { return question.required > 0 });
                } else {
                    return fullQuestions.filter(function (question) { return question.topic == parseInt(topicCode) });
                }
            }

            const resetTopic = (fullQuestions) => {
                const numOfQuestion = fullQuestions.length
                const numOfTopic1 = fullQuestions.filter(function (question) { return question.topic == 1 }).length
                const numOfTopic2 = fullQuestions.filter(function (question) { return question.topic == 2 }).length
                const numOfTopic3 = fullQuestions.filter(function (question) { return question.topic == 3 }).length
                const numOfTopic4 = fullQuestions.filter(function (question) { return question.topic == 4 }).length
                const numOfTopic5 = fullQuestions.filter(function (question) { return question.topic == 5 }).length
                const numOfTopic6 = fullQuestions.filter(function (question) { return question.topic == 6 }).length
                const numOfTopic7 = fullQuestions.filter(function (question) { return question.topic == 7 }).length
                const numOfTopic8 = fullQuestions.filter(function (question) { return question.required > 0 }).length

                const topics = [
                    { "code": 0, "display": "Toàn bộ câu hỏi", "subTitle": `${numOfQuestion} câu`, "num": numOfQuestion },
                    { "code": 1, "display": "Khái niệm và quy tắc giao thông", "subTitle": `${numOfTopic1} câu`, "num": numOfTopic1 },
                    { "code": 2, "display": "Nghiệp vụ vận tải", "subTitle": `${numOfTopic2} câu`, "num": numOfTopic2 },
                    { "code": 3, "display": "Văn hoá và đạo đức", "subTitle": `${numOfTopic3} câu`, "num": numOfTopic3 },
                    { "code": 4, "display": "Kỹ thuật lái xe", "subTitle": `${numOfTopic4} câu`, "num": numOfTopic4 },
                    { "code": 5, "display": "Cấu tạo và sữa chữa", "subTitle": `${numOfTopic5} câu`, "num": numOfTopic5 },
                    { "code": 6, "display": "Biển báo và đường bộ", "subTitle": `${numOfTopic6} câu`, "num": numOfTopic6 },
                    { "code": 7, "display": "Sa hình", "subTitle": `${numOfTopic7} câu`, "num": numOfTopic7 },
                    { "code": 8, "display": "Câu liệt", "subTitle": `${numOfTopic8} câu`, "num": numOfTopic8 }];

                return topics;
            };

            const licenseCode = localStorage.getItem("is_license") || ""
            const { fullQuestions } = freshQuestions(licenseCode);
            const topics = resetTopic(fullQuestions);

            const topicCode = getParaCurr("topic");
            const topic = topics.filter(function (topic) { return topic.code == topicCode })[0];
            const questions = loadQuestionByTopic(topicCode);
            return { licenseCode, fullQuestions, topic, questions }
        },
        []
    );

    const licenseCode = initData.licenseCode;
    const topic = initData.topic;
    const questions = initData.questions;

    const getAnswerClass = (ansIndex) => {
        if (!showResult) {
            return ""
        }

        let answer = question.answers[ansIndex];
        if (answer.correct) {
            return "correct"
        } else if (isAnswered(licenseCode, question.index, ansIndex)) {
            return "wrong"
        } else {
            return ""
        }
    };

    const [index, setIndex] = useState(parseInt(getParaCurr("index")))
    const [question, setQuestion] = useState(questions[0])
    const showResult = true

    const nextQuestion = () => {
        let nextIndex = index + 1
        if (nextIndex > questions.length - 1) nextIndex = 0;
        setIndex(nextIndex)
        setQuestion(questions[nextIndex])
    }

    const prevQuestion = () => {
        let nextIndex = index - 1
        if (nextIndex < 0) nextIndex = questions.length - 1;
        setIndex(nextIndex)
        setQuestion(questions[nextIndex])
    }

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/list-topic">
                    <span className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>{topic.display}</strong>
                </div>
                <div className="right"></div>
            </div>
            <div style={{ height: '50px' }}></div>
            <div id="details-page">
                <div className='question-card' id="q-details" style={{ maxWidth: '600px' }}>
                    <div>
                        <b >Câu {question.no}: {question.text}</b>
                    </div>
                    <div id="q-image" style={{ marginTop: '10px', textAlign: 'center', display: question.image ? 'block' : 'none' }}>
                        <img src={`images/questions/${question.image}`} alt="Question" />
                    </div>
                    <div id="answers" style={{ marginTop: '10px' }}>
                        {question.answers.map((answer, index) => (
                            <div key={index} className={`answer ${getAnswerClass(index)}`}>
                                <div style={{ width: '30px' }}>
                                    <input style={{ width: '20px', height: '20px' }} type="radio" name="ansRadio" disabled checked={answer.correct} />
                                </div>
                                <div className="a-text">{index + 1}. {answer.text}</div>
                            </div>
                        ))}
                    </div>

                </div>
                <div style={{ height: '30px' }}></div>
                <div id="q-tip" style={{ marginTop: '10px', display: question.tip && showResult ? 'block' : 'none' }}>
                    <b>Hướng dẫn: </b>{question.tip}
                </div>
                <div style={{ height: '80px' }}></div>
                <div className="menu" style={{ maxWidth: '600px' }}>
                    <div className="item" onClick={prevQuestion}>
                        <i className="fa fa-chevron-left"></i>
                    </div>
                    <Link className="item" to="/list">
                        <i className="fa fa-th"></i>
                    </Link>
                    <div className="item" onClick={nextQuestion}>
                        <i className="fa fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Question;