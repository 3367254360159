import { useState } from "react";
import { fullLicenses } from './global_question';
import { Link } from "react-router-dom";

const Setting = () => {
    const [licenseCode, setLicenseCode] = useState(localStorage.getItem("is_license") || "")

    const chooseLicense = (licenseCode) => {
        localStorage.setItem("is_license", licenseCode);
        setLicenseCode(licenseCode)
    }

    return (
        <div>
            <div className="head-navigation">
                <a className="left" href="/">
                    <span  className="fa fa-chevron-left"></span>
                </a>
                <div className="center">
                    <strong>Cài đặt</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div id="list-danger-page" className="page has-menu">
                <div id="list">
                    {
                        fullLicenses.map(license => (
                            <div aria-hidden="true" key={license.code} className={"item"} onClick={(e) => { chooseLicense(license.code) }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ paddingRight: '10px' }}>
                                        <input type="radio" style={{ width: '25px', height: '25px' }} name="licRadio" checked={license.code == licenseCode} onChange={(e) => { chooseLicense(license.code) }} />
                                    </div>
                                    <div>
                                        <b >Hạng {license.code}</b>
                                        <div style={{ color: '#ffffffb0' }}>{license.display}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="item">
                        <h3>
                            <span style={{  color: 'red', width: '25px', height: '25px', marginRight: '10px' }} className="fa fa-trash"></span>
                            <Link to="/reset">Xoá lịch sử ôn tập và thi</Link>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Setting;