import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";
import Setting from "./Setting"
import List from "./List"
import Detail from "./Detail"
import ListTopic from "./ListTopic"
import Question from "./Question"
import UserGuide from './UserGuide';
import ListSign from './ListSign';
import Sign from './Sign';
import ListLaw from './ListLaw';
import ListViolation from './ListViolation';
import Violation from './Violation';
import ListWrong from './ListWrong';
import ListExam from './ListExam';
import Exam from './Exam';
import Random from './Random';
import Reset from './Reset';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="setting" element={<Setting />} />
      <Route path="reset" element={<Reset />} />
      <Route path="list" element={<List />} />
      <Route path="detail" element={<Detail />} />
      <Route path="list-topic" element={<ListTopic />} />
      <Route path="question" element={<Question />} />
      <Route path="user-guide" element={<UserGuide />} />
      <Route path="list-sign" element={<ListSign />} />
      <Route path="sign" element={<Sign />} />
      <Route path="list-law" element={<ListLaw />} />
      <Route path="list-violation" element={<ListViolation />} />
      <Route path="violation" element={<Violation />} />
      <Route path="list-wrong" element={<ListWrong />} />
      <Route path="list-exam" element={<ListExam />} />
      <Route path="exam" element={<Exam />} />
      <Route path="random" element={<Random />} />
    </Routes>
  </HashRouter>,
);
reportWebVitals();
