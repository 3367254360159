import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { fullLicenses, freshQuestions } from './global_question';

Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

function Exam() {
    const initData = useMemo(
        () => {
            const licenseCode = localStorage.getItem("is_license") || ""
            const license = fullLicenses.filter((lic) => { return lic.code == licenseCode })[0];
            const examCode = 99
            const { fullQuestions } = freshQuestions(licenseCode);
            let questions = [];

            for (let i=1; i<=7; i++){
                let randOfTopic = fullQuestions.filter(function(question){return question.topic == i})
                let num = license.randQuestions.filter(function (rand) {return rand.topicCode == i})[0].num
        
                let set = new Set()
                if (num > 0) {
                    while (set.size < num) {
                        let rand = randOfTopic.random()
                        set.add(rand)
                    }
                }
        
                set.forEach(function(item) {
                    questions.push(item)
                })
            }

            return { licenseCode, license, examCode, questions }
        },
        []
    );

    const licenseCode = initData.licenseCode;
    const license = initData.license;
    const examCode = initData.examCode;
    const questions = initData.questions

    const [countDown, setCountDown] = useState(license.timer);
    const [timer, setTimer] = useState('');
    const [index, setIndex] = useState(0);
    const [question, setQuestion] = useState(initData.questions[0]);
    const [dangerCss, setDangerCss] = useState(initData.questions[0].required > 0 ? "color:#ff9400" : "");
    const [answerIndex, setAnswerIndex] = useState(-1);

    const clearExamAnswer = (licenseCode, examCode) => {
        console.log("clear");
        for (let i = 1; i <= 650; i++) {
            let key = "is_answer_" + licenseCode + "_" + examCode + "_" + i
            localStorage.removeItem(key);
        }
    }

    useEffect(() => {
        clearExamAnswer(licenseCode, examCode);

        const interval = setInterval(() => {
            setCountDown(prevCountDown => {
                let newCountDown = prevCountDown - 1;
                let minutes = Math.floor(newCountDown / 60);
                let seconds = Math.floor(newCountDown % 60);
                setTimer(`${minutes}:${seconds}`);
                if (newCountDown === 0) {
                    clearInterval(interval);
                    submit();
                }
                return newCountDown;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const load = (idx) => {
        setAnswerIndex(-1);
        setIndex(idx);
        setQuestion(questions[idx]);
        setDangerCss(questions[idx].required > 0 ? "#ff9400" : "");
    };

    const nextQuestion = () => {
        let idx = index + 1;
        if (idx > questions.length - 1) idx = questions.length - 1;
        load(idx);
    };

    const prevQuestion = () => {
        let idx = index - 1;
        if (idx < 0) idx = 0;
        load(idx);
    };

    const toggleAnswer = (ansIndex) => {
        localStorage.setItem("is_answer_" + licenseCode + "_" + examCode + "_" + question.index, ansIndex);
        localStorage.setItem("is_answer_" + licenseCode + "_" + question.index, ansIndex);
        setAnswerIndex(ansIndex);
    };

    const isAnswered = (ansIndex) => {
        return localStorage.getItem("is_answer_" + licenseCode + "_" + examCode + "_" + question.index) == ansIndex ? "checked" : "";
    };

    const getAnswerClass = (ansIndex) => {

    };

    const getExamAnswered = (licenseCode, examCode, questionIndex) => {
        return localStorage.getItem("is_answer_" + licenseCode + "_" + examCode + "_" + questionIndex)
    };

    const isExamAnsweredCorrect = (question, licenseCode, examCode) => {
        for (var answerIndex = 0; answerIndex < question.answers.length; answerIndex++) {
            var answer = question.answers[answerIndex];

            if (answer.correct && getExamAnswered(licenseCode, examCode, question.index) == answerIndex) return true;
        }
        return false;
    };

    const hasExamAnswered = (licenseCode, examCode, questionIndex) => {
        var key = "is_answer_" + licenseCode + "_" + examCode + "_" + questionIndex
        var value = localStorage.getItem(key)
        if (value) {
            return true;
        } else {
            return false;
        }
    };

    const saveExam = (licenseCode, examCode, result) => {
        localStorage.setItem("is_saveexam_" + licenseCode + "_" + examCode, result)
    };

    const submit = () => {
        const saveAnses = questions.map(function(question){
            return isExamAnsweredCorrect(question, licenseCode, examCode)
        })
        const dangerQuestions = questions.filter((question) => question.required > 0);
        const dangerCorrectAnses = dangerQuestions.map((question) => isExamAnsweredCorrect(question, licenseCode, examCode)).filter((correct) => correct === true);
        const danger = dangerCorrectAnses.length;
        const passed = saveAnses.filter((ans) => ans === true).length;
        const result = (passed >= license.pass && danger >= dangerQuestions.length) ? 1 : 0;
        const hasAns = questions.filter((question) => hasExamAnswered(licenseCode, examCode, question.index)).length;
        const unchecked = questions.length - hasAns;
        const failed = questions.length - (passed + unchecked);
        const duration = license.timer - countDown;
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        const timer = `${minutes}:${seconds}`;

        alert(`Kết quả: ${result.result == 1 ? "ĐẠT" : "CHƯA ĐẠT"} \n - Số câu đúng: ${passed} \n - Số câu sai: ${failed} \n - Câu liệt đúng: ${danger}/${dangerQuestions.length}`)
        
    };

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/list-exam">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Đề ngẫu nhiên</strong>
                </div>
                <div className="right">

                </div>
            </div>

            <h4></h4>

            <div id="details-page">
                <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', maxWidth: '600px' }}>
                    <div style={{ width: '50%', textAlign: 'left', fontSize: '16px' }}>Thời gian: {timer}</div>
                    <Link style={{ width: '50%', textAlign: 'right', display: 'flex', placeContent: 'end' }} to="/list-exam">
                        <div className='card' style={{ color: 'white', fontSize: '28px', width: '120px', height: '40px', margin: '0px', fontSize: '20px' }} onClick={submit}>Nộp bài</div>
                    </Link>
                </h1>
                <h4></h4>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {index + 1}/{questions.length}
                </div>
                <h4></h4>

                <div className='question-card' id="q-details" style={{ maxWidth: '600px' }}>
                    <div>
                        <b ><span style={{ color: dangerCss }}>Câu {question.no}:</span> {question.text}</b>
                    </div>
                    <div id="q-image" style={{ marginTop: '10px', textAlign: 'center', display: question.image ? 'block' : 'none' }}>
                        <img src={`images/questions/${question.image}`} alt="Question Image" />
                    </div>
                    <div id="answers" style={{ marginTop: '10px' }}>
                        {question.answers.map((answer, ansIndex) => (
                            <div className={`answer ${getAnswerClass(ansIndex)}`} key={ansIndex} onClick={() => toggleAnswer(ansIndex)}>
                                <div style={{ width: '30px' }}>
                                    <input style={{ width: '20px', height: '20px' }} type="radio" name="ansRadio" checked={isAnswered(ansIndex)} onChange={(e) => { toggleAnswer(ansIndex) }} />
                                </div>
                                <div className="a-text">{ansIndex + 1}. {answer.text}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ height: '80px' }}></div>
                <div className="menu" style={{ maxWidth: '600px' }}>
                    <div className="item" onClick={prevQuestion}>
                        <i className="fa fa-chevron-left"></i>
                    </div>
                    <Link className="item" to="/list">
                        <i className="fa fa-th"></i>
                    </Link>
                    <div className="item" onClick={nextQuestion}>
                        <i className="fa fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exam;
