import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetSettings() {
    const reset = () => {
        localStorage.clear();
        localStorage.setItem("is_license", "B2");
        toast("Khôi phục dữ liệu thành công!");
    }

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/">
                    <span  className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Cài đặt lại</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div className="page has-menu">
                <div>
                    Thao tác này sẽ khôi phục dữ liệu của phần mềm trở về thời điểm ban đầu, mọi câu trả lời mà bạn đã làm sẽ bị xóa. Bạn có chắc chắn muốn tiếp tục?
                </div>
                <p style={{ textAlign: 'center' }}>
                    <div onClick={reset} className="btn btn-danger">&nbsp;&nbsp;&nbsp;Có&nbsp;&nbsp;&nbsp;</div>

                    <a className="btn" style={{ marginLeft: '5px' }} href="/">Không</a>
                </p>
                <ToastContainer />
            </div>
        </div>
    );
}

export default ResetSettings;
