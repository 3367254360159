import { freshQuestions } from './global_question';
import { Link } from "react-router-dom";
import { isAnsweredWrong, hasAnswered } from "./global_js";
import { useMemo } from "react";


const List = () => {
  const initData = useMemo(
    () => {
      const licenseCode = localStorage.getItem("is_license") || "";
      const { fullQuestions } = freshQuestions(licenseCode);

      return { licenseCode, fullQuestions };
    },
    []
  );

  const licenseCode = initData.licenseCode;
  const fullQuestions = initData.fullQuestions;

  let countChuaLam = 0
  let countDung = 0
  let countSai = 0

  const list = fullQuestions.map((question) => {
    let danger_css = question.required > 0 ? "danger" : ""
    let css_class = "" + danger_css;

    if (!hasAnswered(question, licenseCode, question.index)) {
      countChuaLam++;
    } else if (isAnsweredWrong(question, licenseCode, question.index)) {
      countSai++;
      css_class = "wrong" + danger_css;
    } else {
      countDung++;
      css_class = "correct" + danger_css;
    }

    return { index: question.no, css_class: css_class }
  })

  return (
    <div>
      <div className="head-navigation">
        <a className="left" href="/">
          <span  className="fa fa-chevron-left"></span>
        </a>
        <div className="center">
          <strong>Ôn tập</strong>
        </div>
        <div className="right"></div>
      </div>
      <p></p>
      <div id="list-page" className="page has-menu">
        <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', padding: '10px' }}>
          <div>
            <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
              <img style={{ height: '24px', width: '24px' }} src="images/assets/check.png" alt="Check" />
              <span style={{ paddingLeft: '10px', color: '#449d44' }} id="countDung">{countDung} đúng</span>
            </div>
            <a href="/list-wrong">
              <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
                <img style={{ height: '24px', width: '24px' }} src="images/assets/wrongcheck.png" alt="Wrong Check" />
                <span style={{ paddingLeft: '10px', color: '#d9534f' }} id="countSai">{countSai} sai</span>
              </div>
            </a>
            <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
              <img style={{ height: '24px', width: '24px' }} src="images/assets/uncheck.png" alt="Uncheck" />
              <span style={{ paddingLeft: '10px' }} id="countChuaLam">{countChuaLam} chưa làm</span>
            </div>
          </div>
        </div>
        <div id="list">
          {list.map(item => (
            <Link className={item.css_class} to={`/detail?index=${item.index}`} key={item.index}>
              {item.index}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default List;