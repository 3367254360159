import './App.css';
import { freshQuestions } from './global_question';
import { Link } from "react-router-dom";

function App() {
  const licenseCode = localStorage.getItem("is_license") || "B2"
  const { fullQuestions, dangerQuestions } = freshQuestions(licenseCode)

  const numofQuestion = fullQuestions.length
  const numofDanger = dangerQuestions.length

  if (localStorage.getItem("is_license") == null) {
    localStorage.setItem("is_license", licenseCode);
  }

  return (
    <div>
      <Link to="/setting">
        <div style={{ display: 'flex' }}></div>
        <div>
          <h2 style={{ textAlign: 'center', marginBottom: '0px', color: '#ffffff' }}>Ôn thi GPLX hạng {licenseCode} <span>&nbsp;</span> <span className="fa fa-cog"></span></h2>
          <div style={{ textAlign: 'center', fontSize: '11px', color: '#ffffff' }}>( Nhấn vào đây để chọn ôn thi các hạng lái xe khác )</div>
        </div>
      </Link>
      <div style={{height: '30px'}}></div>
      <Link to="https://gplx.app">
      <div style={{ textAlign: 'center', fontSize: '13px', color: '#ffffff' }}><span className="fa fa-undo"></span> Quay về giao diện truyền thống</div>
      </Link>
      <div className="main-home">
        <div className="page page-home">
          <div className="card" style={{ width: '333px', height: '333px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link className="item item-random" to="/random">
                <div>
                  <em className="fa-home-page fa-random"></em>
                  <div style={{ paddingTop: '16px' }}>Đề ngẫu nhiên</div>
                </div>
              </Link>
              <Link className="item item-exam" to="/list-exam">
                <div>
                  <em className="fa-home-page fa-graduation-cap"></em>
                  <div style={{ paddingTop: '16px' }}>Thi theo bộ đề</div>
                </div>
              </Link>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link className="item item-full" to="/list">
                <div>
                  <em className="fa-home-page fa-th"></em>
                  <div style={{ paddingTop: '16px' }}>{numofQuestion} câu hỏi</div>
                </div>
              </Link>
              <Link className="item item-topic" to="/list-topic">
                <div>
                  <em className="fa-home-page fa-cubes"></em>
                  <div style={{ paddingTop: '16px' }}>Chủ đề</div>
                </div>
              </Link>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <strong></strong>
          </div>

          <div className="card" style={{ width: '333px', height: '333px' }}>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link className="item item-danger" to="/question?index=0&topic=8">
                <div>
                  <em className="fa-home-page fa-heart"></em>
                  <div style={{ paddingTop: '16px' }}>{numofDanger} câu liệt</div>
                </div>
              </Link>
              <Link className="item item-wrong" to="/list-wrong">
                <div>
                  <em className="fa-home-page fa-circle-xmark"></em>
                  <div style={{ paddingTop: '16px' }}>Các câu sai</div>
                </div>
              </Link>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link className="item item-sign" to="/list-sign">
                <div>
                  <em className="fa-home-page fa-sign-hanging"></em>
                  <div style={{ paddingTop: '16px' }}>Biển báo</div>
                </div>
              </Link>
              <Link className="item item-law" to="/list-law">
                <div>
                  <em className="fa-home-page fa-balance-scale"></em>
                  <div style={{ paddingTop: '16px' }}>Luật</div>
                </div>
              </Link>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <strong></strong>
          </div>
          <div className="card" style={{ width: '333px', height: '333px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a className="item" style={{ backgroundColor: '#4086cd' }} href="https://apps.apple.com/vn/app/600-câu-hỏi-giấy-phép-lái-xe/id1520672917">
                <img style={{ borderRadius: '22px' }} src="images/assets/ios_qr.svg" alt="iOS QR Code" />
              </a>
              <a className="item" style={{ backgroundColor: '#03a058' }} href="https://play.google.com/store/apps/details?id=me.khangle.onthigplx">
                <img style={{ borderRadius: '22px' }} src="images/assets/android_qr.svg" alt="Android QR Code" />
              </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a className="item" style={{ backgroundColor: '#4086cd' }} href="https://apps.apple.com/app/id1633216075">
                <img style={{ borderRadius: '22px' }} src="images/assets/ios_mophong_qr.svg" alt="iOS Mophong QR Code" />
              </a>
              <a className="item" style={{ backgroundColor: '#03a058' }} href="https://play.google.com/store/apps/details?id=me.khangle.gplx120">
                <img style={{ borderRadius: '22px' }} src="images/assets/android_mophong_qr.svg" alt="Android Mophong QR Code" />
              </a>
            </div>
          </div>

        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', color: '#ffffff', flexDirection: 'column', placeItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', color: '#ffffff', flexDirection: 'column', placeItems: 'end', gap: '12px' }}>
          <a className="item2" href="nd100.html">
            Nghị định 100 <span style={{ color: '#ffffff' }} className="fa fa-chevron-right"></span>
          </a>
          <a className="item2" href="https://phatnguoi.info">
            Tra phạt nguội <span style={{ color: '#ffffff' }} className="fa fa-chevron-right"></span>
          </a>
          <a className="item2" href="https://mophong.gplx.app">
            120 tình huống mô phỏng <span style={{ color: '#ffffff' }} className="fa fa-chevron-right"></span>
          </a>
          <Link className="item2" to="/user-guide">
            Hướng dẫn <span style={{ color: '#ffffff' }} className="fa fa-chevron-right"></span>
          </Link>
        </div>
      </div>
      <h4></h4>
      <div style={{ color: '#ffffff', fontSize: '10px', textAlign: 'center', padding: '10px' }}>Powered by <a href="https://khangle.me">KL Studio</a></div>

    </div>
  );
}

export default App;
