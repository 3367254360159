import { Link } from "react-router-dom";

const UserGuide = () => {
    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/">
                    <span className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Hướng dẫn sử dụng</strong>
                </div>
                <div className="right"></div>
            </div>
            <p></p>
            <div className="page has-menu" style={{ textAlign: 'justify' }}>
                <h1>Chúc các bạn thi đậu</h1>
                <h3>1. Trãi nghiệm ứng dụng 600 câu hỏi ôn thi GPLX trên điện thoại</h3>
                <a href="https://apps.apple.com/vn/app/600-câu-hỏi-giấy-phép-lái-xe/id1520672917">iOS</a>
                <p>
                    <a href="https://apps.apple.com/vn/app/600-câu-hỏi-giấy-phép-lái-xe/id1520672917">
                        <img style={{ borderRadius: '10px' }} src="images/assets/ios_qr.svg" />
                    </a>
                </p>
                <p><a href="https://play.google.com/store/apps/details?id=me.khangle.onthigplx">Android</a></p>
                <p>
                    <a href="https://play.google.com/store/apps/details?id=me.khangle.onthigplx">
                        <img style={{ borderRadius: '10px' }} src="images/assets/android_qr.svg" />
                    </a>
                </p>
                <p>Hỗ trợ tác giả bằng cách cài đặt ứng dụng trên CH Play (Android) hay Appstore (iOS) và đánh giá 5 sao.
                    Xin chân thành cám ơn và <strong>chúc các bạn thi đậu</strong>
                </p>
                <h3>2. Ngày 1/8/2020 áp dụng bộ 600 câu hỏi thi sát hạch lái xe</h3>
                <p>Bộ đề 600 câu hỏi luật giao thông đường bộ kế thừa từ bộ đề 450 câu hỏi luật giao thông đừng bộ đã được nghiên
                    cứu từ nhiều nước phát triển, được cải tiến để loại bỏ những câu học vẹt, những câu hỏi không liên quan đến học
                    lái xe hiện nay.
                </p>
                <p>
                    Điểm đặc biệt trong bộ đề 600 câu hỏi học lý thuyết lái xe chỉ có 1 đáp án đúng trong câu trả lời (bộ đề 450 cũ
                    có rất nhiều câu tới 2 đáp án). Trong đề thi sát hạch có 1 câu hỏi điểm liệt (câu hỏi về tình huống mất an toàn
                    giao thông nghiêm trọng)
                </p>
                <p>
                    Bộ 600 câu hỏi luật giao thông đường bộ bổ sung thêm nội dung mới về biển báo, tốc độ xe được phép chạy, quy
                    định về nồng độ cồn theo nghị định 100, cập nhật các tiêu chuẩn theo Quy chuẩn quốc gia về báo hiệu đường bộ
                    hiện hành và các tình huống giao thông sát với thực tế hơn.
                </p>
                <p>
                    Số câu hỏi trong các đề thi các hạng A1, A2, A3, A4, B1, B11, B2, C, D và các hạng F cũng thay đổi tăng lên, số
                    câu hỏi ôn tập trong bộ đề các hạng cũng tăng lên.

                </p>
                <p> Đặc biệt, trong bộ 600 câu học lý thuyết lái xe mới này có 60 câu hỏi điểm liệt (câu hỏi về tình huống mất an
                    toàn giao thông nghiêm trọng). Mỗi đề thi điều có 1 câu hỏi điểm liệt, nếu thí sinh chỉ cần trả lời sai câu hỏi
                    điểm liệt coi như bị rớt.
                </p>
                <p>
                    Những câu hỏi điểm liệt là những tình huống giao thông rất nghiêm trọng là những điểm mang tính chất cơ bản, cốt
                    lõi mà người lái xe phải hiểu, luôn ghi nhớ thực hiện đúng trong suốt quá trình hành nghề lái xe. Đó là những
                    hành vi bị nghiêm cấm trong quy tắc giao thông như những hành vi gây mất an toàn tại nơi đường bộ giao với đường
                    sắt, trên đường cao tốc, nhường đường cho xe ưu tiên, hành vi điều khiển xe sau khi uống rượu bia, dùng ma tý …
                </p>
                <p>
                    Ngoài ra, có những câu hỏi thuộc phần nội dung liên quan đến kỹ thuật lái xe khi xuống dốc cao và dài, khi qua
                    đoạn đường khuất tầm nhìn, khi qua đường sắt, khi tầm nhìn bị hạn chế, khi ra vào đường cao tốc. Đặc biệt liên
                    quan đến kỹ năng xử lý tình huống giao thông khi qua ngã tư, qua đường sắt, qua đoạn đường khuất tầm nhìn, khi
                    ra vào đường cao tốc.</p>

                <a href="/structure.html"><h3 >3. Danh sách câu hỏi ⓘ</h3></a>

                <p>
                    Từ màn hình chính, click "Danh sách câu hỏi". Danh sách này có chứa 600 câu hỏi thi lý thuyết sát hạch lái xe.
                </p>
                <p>
                    Những câu hỏi chưa làm sẽ có nền màu trắng, câu làm đúng có nền màu xanh, câu làm sai có nền màu đỏ.
                </p>
                <p>
                    Click vào một câu hỏi để bắt đầu luyện tập từ câu hỏi đó.
                </p>

                <h3>4. Chi tiết câu hỏi</h3>

                <p>
                    Đây là màn hình hiển thị nội dung câu hỏi và các phương án trả lời.
                    Hãy lựa chọn các phương án trả lời phù hợp và nhấn vào biểu tượng mũi tên để đi đến câu hỏi tiếp theo hoặc trước
                    đó.
                </p>
                <p>
                    Khi cần trợ giúp, hãy nhấn vào biểu tượng dấu hỏi, đáp án và gợi ý trả lời (nếu có) sẽ được hiển thị.
                </p>

                <h3>5. Danh sách câu làm sai</h3>

                <p>
                    Để xem danh sách câu đã làm sai, từ màn hình chính, click vào "câu sai".
                </p>

                <h3>6. Danh sách câu bị điểm liệt</h3>

                <p>
                    Câu liệt là câu mà chọn sai sẽ bị đánh rớt cả bài. Để xem danh sách các câu liệt, từ màn hình chính, click vào
                    "60 câu liệt". 60 câu này bắt buộc phải học thuộc lòng.
                </p>

                <a href="https://phatnguoi.info"><h3 >7. Tra cứu phạt nguội ⓘ</h3></a>

                <h3>8. Xóa dữ liệu làm lại từ đầu</h3>

                <p>
                    Để xóa dữ liệu làm lại từ đầu, <Link className="left" to="/reset"> nhấn vào đây
                </Link>.
                </p>
            </div>
        </div>
    );
}

export default UserGuide;
