import { freshQuestions } from './global_question';
import { Link } from "react-router-dom";
import { useState, useMemo } from "react";
import { getParaCurr } from "./global_js";

const Detail = () => {
    const initData = useMemo(
        () => {
            const licenseCode = localStorage.getItem("is_license") || "";
            const { fullQuestions } = freshQuestions(licenseCode);

            return { licenseCode, fullQuestions };
        },
        []
    );

    const licenseCode = initData.licenseCode;
    const fullQuestions = initData.fullQuestions;

    const [index, setIndex] = useState(parseInt(getParaCurr("index")));
    const [question, setQuestion] = useState(fullQuestions.filter(function (question) { return question.no == index })[0]);
    const [dangerCss, setDangerCss] = useState(fullQuestions.filter(function (question) { return question.no == index })[0].required > 0 ? "#ff9400" : "");

    const getAnswerClass = (ansIndex) => {
        let answer = question.answers[ansIndex];
        if (answer.correct) {
            return "correct";
        } else if (localStorage.getItem("is_answer_" + licenseCode + "_" + question.index) == ansIndex) {
            return "wrong";
        } else {
            return "";
        }
    };

    const isAnswered = (ansIndex) => {
        return localStorage.getItem("is_answer_" + licenseCode + "_" + question.index) == ansIndex ? "checked" : "";
    };

    const nextQuestion = () => {
        let nextIndex = index + 1;
        if (nextIndex >= fullQuestions.length) nextIndex = fullQuestions.length;
        const nextQuestion = fullQuestions.filter((item) => { return item.no == nextIndex })[0];
        setIndex(nextIndex);
        setQuestion(nextQuestion);
        setDangerCss(nextQuestion.required > 0 ? "#ff9400" : "");
    };

    const prevQuestion = () => {
        let nextIndex = index - 1;
        if (index <= 1) nextIndex = 1;
        const nextQuestion = fullQuestions.filter((item) => { return item.no == nextIndex })[0];
        setIndex(nextIndex);
        setQuestion(nextQuestion);
        setDangerCss(nextQuestion.required > 0 ? "#ff9400" : "");
    };

    return (
        <div>
            <div className="head-navigation">
                <Link className="left" to="/list">
                    <span className="fa fa-chevron-left"></span>
                </Link>
                <div className="center">
                    <strong>Ôn tập</strong>
                </div>
                <div className="right">

                </div>
            </div>

            <div style={{ height: '50px' }}></div>

            <div id="details-page">
                <div className='question-card' id="q-details" style={{ maxWidth: '600px' }}>
                    <div>
                        <b ><span style={{ color: dangerCss }}>Câu {question.no}:</span> {question.text}</b>
                    </div>
                    <div id="q-image" style={{ marginTop: '10px', textAlign: 'center', display: question.image ? 'block' : 'none' }}>
                        <img src={`images/questions/${question.image}`} alt="Question Image" />
                    </div>
                    <div id="answers" style={{ marginTop: '10px' }}>
                        {question.answers.map((answer, ansIndex) => (
                            <div className={`answer ${getAnswerClass(ansIndex)}`} key={ansIndex}>
                                <div style={{ width: '30px' }}>
                                    <input style={{ width: '20px', height: '20px' }} type="radio" name="ansRadio" disabled checked={isAnswered(ansIndex)} />
                                </div>
                                <div className="a-text">{ansIndex + 1}. {answer.text}</div>
                            </div>
                        ))}
                    </div>

                </div>

                <div style={{ height: '30px' }}></div>

                <div id="q-tip" style={{ maxWidth: '600px', margin: 'auto', display: question.tip ? 'block' : 'none' }}>
                    <b>Hướng dẫn: </b>{question.tip}
                </div>

                <div style={{height: '80px'}}></div>

                <div className="menu" style={{ maxWidth: '600px' }}>
                    <div className="item" onClick={prevQuestion}>
                        <em className="fa fa-chevron-left"></em>
                    </div>

                    <Link className="item" to="/list">
                        <em className="fa fa-th"></em>
                    </Link>

                    <div className="item" onClick={nextQuestion}>
                        <em className="fa fa-chevron-right"></em>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detail;